<template>
  <div id="ganjin_index">
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted(){
    //对早期给出去的链接做一个跳转
    location.href = "https://ganjin.wdomilk.com.cn/#/qj/"
  }
};
</script>